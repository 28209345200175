import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import containerStyles from "./container.module.css"

const BackgroundPicture = styled.div`
    display: grid;
    max-height: 100%;
    height: 100vh;

    @media screen and (max-width: 813px) and (orientation: portrait) {
        height: 50vh;
    }

    .img,
    .gatsby-image-wrapper {
        min-width: 100%;
        height: 100%;
        min-height: 600px;
        max-height: 100vh;
        margin: auto;
        object-fit: cover;
        filter: brightness(85%);

        @media screen and (max-width: 813px) {
            min-height: unset;
        }
    }
`

export default function Container( props ) {
    return (
        <div className={containerStyles.container}>
            <BackgroundPicture>
                <Img fluid={props.image} loading="eager"/>
            </BackgroundPicture> 
            <div className={containerStyles.bottomleft}>
                <div style={{ color: "white" }}>{props.bottomLeft}</div>
            </div>
            <div className={containerStyles.bottomright}>
                <div style={{ color: "white" }}>{props.bottomRight}</div></div>
            <div className={containerStyles.topleft}>
                <div style={{ color: "white" }}>{props.topLeft}</div>
            </div>
            <div className={containerStyles.topright}>
                <div style={{ color: "white" }}>{props.topRight}</div>
            </div>
            <div className={containerStyles.centered}>
                <div style={{ color: "white" }}>{props.centered}</div>
            </div>
        </div>
    )
}
